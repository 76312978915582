<script lang="ts" setup>
import { coreTools } from '#forgd/app/composables/nav'

const props = withDefaults(defineProps<Options>(), { contact: true, containerClass: 'max-w-[1200px]' })

interface Options {
  title?: string
  icon?: string
  cta?: string
  ctaBtnText?: string
  contact?: boolean
  containerClass?: false | string
}

const options = computed<Options>(() => {
  return { ...props, ...useRoute().meta }
})

const navSubItems = [
  ...preTgeNav.map(item => ({ ...item, preTge: true })),
  ...listedTokenNav.map(item => ({ ...item, preTge: false })),
]
  .flatMap(item => (item.children || []).map(child => ({ icon: item.icon, preTge: item.preTge, ...child })))
  .filter(item => item.to) as { to: string, label: string, icon?: string, preTge: boolean }[]

const _workbooks = navSubItems
  .filter(item => item.to.startsWith('https://docs.google.com'))
  .filter((item, index, self) => self.findIndex(t => t.to === item.to) === index)
const workbooks = _workbooks
  .map((item) => {
    item.icon = 'i-heroicons-document-chart-bar'
    const hasDuplicateLabel = _workbooks.filter(t => t.label === item.label).length > 1
    if (hasDuplicateLabel) {
      return ({ ...item, label: (item.preTge ? 'Pre-TGE: ' : 'Post-TGE: ') + item.label })
    }
    return item
  })

const thirdParty = navSubItems
  .filter(item => item.to.startsWith('/') && item.to.startsWith('/partner'))
  .filter((item, index, self) => self.findIndex(t => t.label === item.label) === index)

const groups = [
  {
    key: 'tools',
    label: 'Core Tools',
    slot: 'tools',
    commands: coreTools,
  },
  {
    key: 'workbooks',
    label: 'Forgd Workbooks',
    commands: workbooks,
  },
  {
    key: 'third-party',
    label: '3rd Party Tools',
    commands: thirdParty,
  },
]

const router = useRouter()
const runtimeConfig = useRuntimeConfig()

const shouldDisplayContactUsBannerInFooter = computed(() => {
  const availableRoutes = [
    'playbook',
    'playbook-basic',
    'token-settings',
    'token-designer',
    'active-dashboard',
    'market-maker-monitoring-start',
    'market-maker-monitoring-dashboard',
    'liquidity-launch-designer-confirmation',
  ]
  return availableRoutes.includes(router.currentRoute.value.name)
})
</script>

<template>
  <div>
    <NuxtLayout name="core-dashboard" v-bind="props">
      <template v-if="$slots.sidebar" #sidebar>
        <slot name="sidebar" />
      </template>
      <template #header>
        <slot name="header" />
      </template>
      <template #footer>
        <slot name="footer" />
        <div v-if="shouldDisplayContactUsBannerInFooter && options.contact" class="mx-auto border-t border-forgd-gray-300" :class="containerClass ? [containerClass, 'p-5'] : []">
          <!-- Footer call to action, something generic -->
          <AppContact class="!mt-0">
            <template #default>
              {{ options.cta || 'Contact us for a 1:1 consultation regarding all things related to your project.' }}
            </template>
            <template #cta>
              <UButton
                size="xl"
                color="black"
                class="px-12 py-3"
                :to="runtimeConfig.public.consultationUrl"
                target="_blank"
              >
                {{ options.ctaBtnText || 'Schedule a 1:1 Consultation' }}
              </UButton>
            </template>
          </AppContact>
        </div>
      </template>
      <template #slideover="{ modelValue, navTitle: NavTitle, navList: NavList }">
        <AppMenuSlideoverContent :model-value="modelValue" :nav-title="NavTitle" :nav-list="NavList" />
      </template>
      <slot />
    </NuxtLayout>
    <SwitchAccountProfileModal />
    <ForbiddenTeammateActionModal />
    <ClientOnly>
      <LazyCoreSearchModal :groups="groups">
        <template #tools-icon="{ command }">
          <img :src="command.icon" alt="" width="40" height="40" class="size-10">
        </template>
      </LazyCoreSearchModal>
    </ClientOnly>
  </div>
</template>
