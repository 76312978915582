<script setup lang="ts">
import { usePermissionsDialogStore } from '#auth/composables/usePermissionsDialogStore'

const auth = useAuth()
const permissionsDialogStore = usePermissionsDialogStore()
const { isForbiddenModalOpen } = storeToRefs(permissionsDialogStore)
const { closeForbiddenModal } = permissionsDialogStore
</script>

<template>
  <UiModal :is-open="isForbiddenModalOpen" @close="closeForbiddenModal">
    <div class="space-y-5 font-display">
      <div class="space-y-[0.625rem]">
        <UIcon
          name="i-heroicons-exclamation-circle"
          class="w-[1.875rem] h-[1.875rem] text-primary-300"
        />
        <h1 class="text-xl font-semibold text-text-primary">
          You don't have access to perform this action
        </h1>
        <p class="text-gray-600 text-sm">
          This action is reserved for admins in {{ auth.project?.ticker }}'s token space. Please reach out to an admin if you need assistance.
        </p>
      </div>
      <div class="flex gap-4">
        <UiButton class="flex-1" @click="closeForbiddenModal">
          Got It
        </UiButton>
      </div>
    </div>
  </UiModal>
</template>
