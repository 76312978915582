<script setup lang="ts">
import { usePermissionsDialogStore } from '#auth/composables/usePermissionsDialogStore'

const authStore = useAuth()
const permissionsDialogStore = usePermissionsDialogStore()
const { isSwitchModalOpen, type, state } = storeToRefs(permissionsDialogStore)
const { closeSwitchModal } = permissionsDialogStore
const { project } = storeToRefs(authStore)

async function handleConfirm() {
  try {
    state.value = 'processing'
    const [result] = await Promise.all([
      useQuery('/projects/{projectId}/promote-research', {
        method: 'patch',
        params: {
          path: {
            projectId: project.value!.id,
          },
        },
        options: {
          toast: true,
        },
      }),
      // Make sure that the processing state is visible for 3 seconds
      new Promise(resolve => setTimeout(resolve, 3000)),
    ])

    if (result.error.value) {
      throw result.error.value
    }

    state.value = 'success'
    authStore.refresh({ from: 'switch-account-profile-modal' })
  }
  catch {
    state.value = 'prompt'
  }
}

async function handleEditTokenDetails() {
  navigateTo('/token-settings')
  closeSwitchModal()
}

const modalHeight = computed(() => {
  if (type.value === 'confirm-switch') {
    return 'h-[200px]'
  }

  return 'h-[250px]'
})
</script>

<template>
  <UiModal :is-open="isSwitchModalOpen" @close="closeSwitchModal">
    <UiStack v-if="state === 'prompt'" :class="modalHeight">
      <div>
        <UiIcon name="i-heroicons-exclamation-circle" class="w-10 h-10 text-forgd-primary-300" />
        <div class="mb-3 font-semibold text-xl text-primary-900">
          {{ type === 'confirm-switch'
            ? 'You are about to switch the account profile.'
            : 'You are currently working with a researcher profile account.'
          }}
        </div>
        <div class="mb-2 text-gray-600 text-base">
          {{ type === 'confirm-switch'
            ? "Don't worry - your progress will be saved, and no work will be lost.\nDo you want to continue?"
            : "To continue with this feature, you'll need to update your account to a \"project\" profile. Don't worry - your progress will be saved, and no work will be lost."
          }}
        </div>
      </div>
      <div class="flex items-center w-full gap-3">
        <UiButton variant="outline" class="grow basis-0" @click="closeSwitchModal">
          Cancel
        </UiButton>
        <UiButton variant="solid" class="grow basis-0" @click="handleConfirm">
          {{ type === 'confirm-switch' ? 'Continue' : 'Switch Account Profile' }}
        </UiButton>
      </div>
    </UiStack>
    <div v-else-if="state === 'processing'" class="flex flex-col items-center justify-center" :class="modalHeight">
      <UiLottie name="processing" size="sm" />
      <div class="mb-3 font-semibold text-xl text-primary-900">
        We are processing your submission...
      </div>
      <div class="text-gray-600 text-base">
        Do not close this window
      </div>
    </div>
    <UiStack v-else-if="state === 'success'">
      <div>
        <UiIcon name="i-heroicons-check-circle" class="w-10 h-10 text-green-600" />
        <div class="mb-3 font-semibold text-xl text-primary-900">
          Your account has been updated
        </div>
        <div class="mb-2 text-gray-600 text-base">
          Your account is now set up with full access to all Forgd tools. We recommend customizing your token details (name, ticker, image, etc.) before proceeding with other tasks.
        </div>
      </div>
      <div class="flex items-center w-full gap-3">
        <UiButton v-if="type === 'confirm-switch'" variant="outline" class="w-full" @click="closeSwitchModal">
          Got it
        </UiButton>
        <template v-if="type === 'require-switch'">
          <UiButton variant="outline" class="grow basis-0" @click="closeSwitchModal">
            I Will Do This Later
          </UiButton>
          <UiButton variant="solid" class="grow basis-0" @click="handleEditTokenDetails">
            Edit Token Details
          </UiButton>
        </template>
      </div>
    </UiStack>
  </UiModal>
</template>
