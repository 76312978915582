<script lang="ts" setup>
import { useNavState } from '#core/utils/nav'
import { isCurrent } from '#imports'
import { useStorage } from '@vueuse/core'

defineProps<{
  modelValue?: 'forgd-academy' | 'tools-and-services' | null
  navTitle: any
  navList: any
}>()

const { slideoverMenu } = useNavState()
const auth = useAuth()
const { project } = storeToRefs(auth)
const isLiquidityLaunchDesignerEnabled = await useFeatureFlag('liquidity-launch-designer')
const toolsAndServicesTab = useStorage<number>('forgd:nav:tools-services-tab', project.value?.tokenListed ? 1 : 0)

const toolsNavLinks = computed(() => {
  if (toolsAndServicesTab.value === 0) {
    return isLiquidityLaunchDesignerEnabled ? coreToolsPreTgeWithLaunchDesigner : coreToolsPreTge
  }
  return coreToolsListed
})

// TODO : remove this when the full-service advisory is ready
const fullServiceAdvisoryReady = false
</script>

<template>
  <div class="max-w-[300px] text-xs">
    <nav v-if="modelValue === 'forgd-academy'" aria-label="Forgd Academy Navigation">
      <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
        <div class="flex items-center gap-2 text-forgd-primary-900">
          <UIcon class="w-5 h-5" name="i-heroicons-academic-cap" />
          <div class="text-sm font-semibold">
            <div>Forgd Academy</div>
          </div>
        </div>
      </div>
      <div v-for="(item, key) in forgdAcademyNav" :key="key" class="p-4">
        <component :is="navTitle" :label="item.label" :to="item.to" :icon="item.icon" />
        <component :is="navList" :items="item.children" />
      </div>
    </nav>
    <div v-else-if="modelValue === 'tools-and-services'">
      <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
        <div class="mb-2 flex items-center gap-2 text-forgd-primary-900">
          <UIcon class="w-5 h-5" name="i-forgd-tools" />
          <div class="text-sm font-semibold">
            <div>Tools & Services</div>
          </div>
        </div>
        <UiSwitch
          v-model="toolsAndServicesTab"
          :options="{
            'Pre-TGE Phase': 0,
            'Listed Phase': 1,
          }"
          class="w-full"
        />
      </div>
      <div class="border-b border-b-neutral-600">
        <div class="p-3">
          <div class="flex items-center justify-between mb-2">
            <div class="text-primary-900 text-xs font-semibold">
              Core Tools
            </div>
            <div>
              <UiBadge size="sm" color="primary">
                Free
              </UiBadge>
            </div>
          </div>
          <NuxtLink v-for="tool in toolsNavLinks" :key="tool.to" :to="tool.to" class="flex items-center gap-2 px-2 py-1 hover:bg-forgd-neutral-400 rounded">
            <div>
              <img :src="tool.icon" alt="" width="40" height="40" class="size-10">
            </div>
            <div class="text-xs text-gray-600">
              {{ tool.label }}
            </div>
            <svg v-if="isCurrent(tool, true)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="ml-1">
              <circle cx="4" cy="4" r="4" fill="#2D73FF" />
            </svg>
          </NuxtLink>
        </div>
      </div>
      <div class="p-4 text-xs border-b border-b-neutral-600">
        <nav v-if="toolsAndServicesTab === 0" aria-label="Pre-TGE Phase  Navigation" class="space-y-4">
          <div v-for="(item, key) in preTgeNav" :key="key">
            <component :is="navTitle" :label="item.label" :to="item.to" :icon="item.icon" />
            <component :is="navList" :items="item.children?.filter(c => !c.coreTool)" />
          </div>
        </nav>
        <nav v-else-if="toolsAndServicesTab === 1" aria-label="Listed Token Navigation" class="space-y-4">
          <div v-for="(item, key) in listedTokenNav" :key="key">
            <component :is="navTitle" :label="item.label" :to="item.to" :icon="item.icon" />
            <component :is="navList" :items="item.children?.filter(c => !c.coreTool)" />
          </div>
        </nav>
      </div>
    </div>
    <div v-if="fullServiceAdvisoryReady" class="p-4">
      <UiBox>
        <div class="flex justify-between mb-5">
          <div>
            <img src="/icons/full-service-advisory.svg" alt="" width="40" height="40" class="size-10">
          </div>
          <div>
            <UiBadge color="green">
              Fee-Based
            </UiBadge>
          </div>
        </div>
        <div class="text-xs mb-5">
          <div class="font-semibold text-primary-900">
            Full-Service Advisory
          </div>
          <div class=" text-gray-600">
            Forgd curates bespoke programs tailored to each project’s unique needs.
          </div>
        </div>
        <div>
          <UiButton trailing icon="i-heroicons-arrow-up-right" variant="outline" target="_blank" to="https://www.forgd.com/solution/full-service-advisory" size="sm" @click="slideoverMenu = null">
            Learn More
          </UiButton>
        </div>
      </UiBox>
    </div>
  </div>
</template>
